<template>
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <b-modal
      id="modal-details-remark"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      scrollable
      size="xl"
      title="Update Remark"
      no-close-on-backdrop
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-card no-body class="pt-0 mt-0">
            <b-card-body class="invoice-padding form-item-section">
              <div class="d-flex mt-1">
                <h5 class="mb-2">Report Update</h5>
              </div>
              <div
                ref="form"
                class="invoice-items repeater-form"
                :style="{ height: trHeight }"
              >
                <b-row
                  v-for="(item, index) in reportData.records"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="3">
                          <div>
                            <validation-provider
                              #default="validationContext"
                              name="Record Date"
                              rules="required"
                            >
                              <b-form-group
                                class="update_date required"
                                label="Record Date"
                                label-for="update_date"
                              >
                                <b-form-input
                                  id="update_date"
                                  :state="getValidationState(validationContext)"
                                  v-model="item.update_date"
                                  style="display: none"
                                />
                                <flat-pickr
                                  v-model="item.update_date"
                                  class="form-control"
                                  :config="{
                                    enableTime: true,
                                    dateFormat: 'Y-m-d H:i',
                                  }"
                                  :disabled="item.user_id != user_id"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </div>
                        </b-col>
                        <b-col cols="12" lg="9">
                          <span v-if="item.user" style="position: absolute; right: 15px"
                            ><span v-if="item.user.id != user_id"
                              >[{{ item.user.name }}]</span
                            ></span
                          >
                          <validation-provider
                            #default="validationContext"
                            name="Report Remark"
                            rules="required"
                          >
                            <b-form-group
                              class="report_remark required"
                              label="Report Remark"
                              label-for="report_remark"
                            >
                              <b-form-input
                                id="report_remark"
                                :state="getValidationState(validationContext)"
                                v-model="item.remark"
                                style="display: none"
                              />
                              <!--  <quill-editor
                                v-model="item.remark"
                                :disabled="item.user_id != user_id"
                                :options="editorOption"
                              /> -->
                              <b-form-textarea
                                v-model="item.remark"
                                :disabled="item.user_id != user_id"
                                rows="3"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <div
                        class="d-flex flex-column justify-content-between border-left py-50 px-25"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index, item)"
                          v-if="item.user_id == user_id"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>
            <b-card-body class="invoice-padding form-item-section">
              <div ref="form" class="repeater-form" :style="{ height: trHeight }"></div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm"
              >
                Add Detail
              </b-button>
            </b-card-body>

            <!-- <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div> -->
          </b-card>
        </b-form>
      </b-overlay>
      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-sm-1"
            @click="ok()"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="cancel()"
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>
<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BCardBody,
  BTable,
  BCard,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import staffWelcomeStoreModule from "../client/staffWelcomeStoreModule";
import { getUserData } from "@/auth/utils";
import { heightTransition } from "@core/mixins/ui/transition";
import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

export default {
  data() {
    return {
      user_id: getUserData().id,
      columns: [
        {
          label: "Date",
          field: "update_date",
        },
        {
          label: "Staff",
          field: "user.name",
        },
        {
          label: "Remark",
          field: "remark",
        },
      ],
    };
  },
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BCardBody,
    BTable,
    BCard,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
    flatPickr,
    BFormTextarea,
  },
  mixins: [heightTransition],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    uploadSuccess(res) {
      // 获取富文本组件实例
      console.log({ res });
      let quill = this.$refs.QuillEditor.quill;
      // 如果上传成功
      if (res) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, "image", res.file_path);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        // 提示信息，需引入Message
        this.$message.error("图片插入失败！");
      }
    },
    onSubmit() {
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("report/updateTask", {
              ...this.reportData,
            })
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.renewData();
              this.$bvModal.hide("modal-details-remark");
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    hide() {
      this.$bvModal.hide("modal-update");
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.reportData.records.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)));
    },
    removeItem(index, item) {
      if (item.id != 0) {
        this.$swal({
          title: "Are you sure?",
          text:
            "Your action is final and you will not be able to retrieve this report update.",
          showCancelButton: true,
          confirmButtonText: "Delete",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$store
              .dispatch("report/deleteTask", { id: item.id })
              .then((response) => {
                this.$swal({
                  text: response.data.message,
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
                this.$emit("refetch-data");
                this.renewData();
                this.hide();
              })
              .catch((error) => {
                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              });
          }
        });
      } else if (this.reportData.records.length > 1) {
        this.reportData.records.splice(index, 1);
      } else {
        this.makeToast(
          "danger",
          "Warning",
          "Please submit at least one reporting detail."
        );
      }
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
  setup(props, { emit }) {
    const REPORT_STORE_MODULE_NAME = "report";

    if (!store.hasModule(REPORT_STORE_MODULE_NAME))
      store.registerModule(REPORT_STORE_MODULE_NAME, staffWelcomeStoreModule);

    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME))
        store.unregisterModule(REPORT_STORE_MODULE_NAME);
    });

    const loading = ref(false);

    const resetreportData = () => {
      props.reportData = JSON.parse(JSON.stringify({}));
    };

    const itemFormBlankItem = {
      id: 0,
      update_date: "",
      remark: "",
      user_id: getUserData().id,
    };

    const options = ref({
      placeholder: "(紀錄未能解決／待處理的問題/進度)",
    });

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetreportData
    );

    //report_type_id : 1 => report, 2 => project_plans, 3 => Licence Control, 4 => Internal Audit
    let requestPath = "/fetchTask";

    const renewData = () => {
      store
        .dispatch(REPORT_STORE_MODULE_NAME + requestPath, {
          id: props.reportData.id,
        })
        .then((response) => {
          emit("update:report-data", response.data.task);
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            if (error.response.status === 404) {
              //props.reportData = undefined;
            }
          }
        });
    };
    const editorOption = {
      modules: {
        toolbar: {
          container: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [
              {
                font: [
                  "SimSun",
                  "SimHei",
                  "Microsoft-YaHei",
                  "KaiTi",
                  "FangSong",
                  "Arial",
                ],
              },
            ],
            [{ align: [] }],
            ["clean"],
            ["link"],
          ],
        },
      },
    };
    return {
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      itemFormBlankItem,
      options,
      renewData,
      editorOption,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 100%;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
  border-radius: 0%;
}
.hideUpload > div {
  display: none;
}
</style>
